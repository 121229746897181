import React from 'react';
import Button from '../../components/Button';
import Title from '../../components/Title';
import SubTitle from '../../components/SubTitle';
import DetailTitle from '../../components/DetailTitle';
import ImgPxgroup01 from '../../assets/images/detail/pxgroup01.png';
import ImgPxgroup02 from '../../assets/images/detail/pxgroup02.png';
import ImgPxgroup03 from '../../assets/images/detail/pxgroup03.png';
import ImgPxgroup04 from '../../assets/images/detail/pxgroup04.png';
import ImgPxgroup05 from '../../assets/images/detail/pxgroup05.png';
import ImgPxgroup06 from '../../assets/images/detail/pxgroup06.png';
import ImgPxgroup07 from '../../assets/images/detail/pxgroup07.png';
import ImgPxgroup08 from '../../assets/images/detail/pxgroup08.png';
import ImgPxgroup09 from '../../assets/images/detail/pxgroup09.png';

const PxgroupScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='全聯 “圈團” 團購服務' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>UI/UX</li>
            <li>iOS</li>
            <li>Android</li>
            <li>WebDev</li>
          </ul>
          <p>
          全聯數位轉型電商平台『圈團』2021年11月正式上線，透過台灣行動支付前三大的app「PX Pay」當行動購物入口，
            讓門市店員變身團購達人，讓購物更有趣！透過店員的推薦，不僅可以享受優惠價格，還能到店裡取貨，
            順便順便逛逛門市其他商品，創造雙贏！
          </p>
          <img src={ImgPxgroup01} alt='thumbnail01' />
          <Title title='Brand Positioning' descript='品牌定位' />

          <SubTitle subtitle='Style Guide' />
          <p className='subtext'>保留全聯品牌的企業色，另外加上亮眼紅的輔助色，
          加上親切友善、年輕現代的店員形象，帶動畫面添增活潑感。</p>
          <img src={ImgPxgroup02} alt='thumbnail02' />

          <SubTitle subtitle='Logo' />
          <p className='subtext'>透過改造「圈團」文字結構，加上圓角增添親和度，
          並加上紅色對話框團購的關鍵字“+1”增添搶購熱潮形象。</p>
          <img src={ImgPxgroup03} alt='thumbnail03' />

          <SubTitle subtitle='Icon' />
          <p className='subtext'>以圓角icon營造親和力，讓品牌形象更溫暖、更易親近，
          並透過圓角icon簡化視覺元素，提升品牌質感。</p>
          <img src={ImgPxgroup04} alt='thumbnail04' />

          <Title title='App Design' descript='設計畫面說明' />
          <img src={ImgPxgroup05} alt='thumbnail05' />
          <img src={ImgPxgroup06} alt='thumbnail06' />
          <img src={ImgPxgroup07} alt='thumbnail07' />

          <SubTitle subtitle='Empty' />
          <p className='subtext'>空畫面設計，透過情境輔助狀態顯示，維持用戶使用APP的興致。</p>
          <img src={ImgPxgroup08} alt='thumbnail08' />

          <SubTitle subtitle='UI Kit' />
          <p className='subtext'>以能重複使用的元件為原則進行設計，增加產出效率確保一致性，並且提高開發和維護的效率。
          </p>
          <img src={ImgPxgroup09} alt='thumbnail09' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default PxgroupScreen;
