import React from "react";
import { H2, H4 } from "../../components/Typography/index";
import Feature from "../../components/Feature";
import Button from "../../components/Button";
import Title from "../../components/Title";
import WorkItem from "../../components/WorkItem";
import IconResearrch from "../../assets/images/ic-research.svg";
import IconDesign from "../../assets/images/ic-design.svg";
import IconTechnology from "../../assets/images/ic-technology.svg";
// import ImgIpet from "../../assets/images/thumbnail/iPet.png";
import ImgGteng from "../../assets/images/thumbnail/gteng.png";
import ImgMayfull from "../../assets/images/thumbnail/mayfull.png";
import ImgFb from "../../assets/images/thumbnail/fb.png";
import ImgPxec from "../../assets/images/thumbnail/pxec.png";
import ImgPxgroup from "../../assets/images/thumbnail/pxgroup.png";
import ImgWallet from "../../assets/images/thumbnail/wallet.png"

const HomeScreen = () => {
  return (
    <>
      <div className="banner">
        <div className="quotes">
          <H2>Good artists copy,</H2>
          <H2>Great artists steal.</H2>
        </div>
        <div className="quotes-name">Pablo Picasso</div>
      </div>
      <div className="container">
        <div className="feature-wrap row">
          <div className="feature-head">
            <Feature text="RESEARCH" icon={IconResearrch} alt="RESEARCH" />
            <Feature text="DESIGN" icon={IconDesign} alt="DESIGN" />
            <Feature text="TECHNOLOGY" icon={IconTechnology} alt="TECHNOLOGY" />
          </div>
          <div className="feature-body">
            <H4>
              自從實踐大學多媒體設計系畢業後，已經從事網頁設計產業 10
              年以上，從單純的 HTML/CSS/JQuery 開始，因應行動裝置普及而投入 RWD
              還有 APP UI/UX設計，感謝這產業一直都進步飛快，UI方面有
              Figma/sketch、前端方面有 React 和 Vue
              的誕生，讓我們開發能更有效率的完成每個作品，喜歡與團隊討論分享到完成專案，每一次都是成長，希望與你能有機會一起實踐創意創新創業。
            </H4>
            <H4>
              專長是網頁 RWD 切版，APP UI
              介面設計，透過了解用戶及其相關使用的場景和目的，建立設計原型和最小可行性產品，不斷的與客戶確認設計方案，最後結合技術開發成品。
            </H4>
          </div>
          <Button text="MORE" go="/about" />
        </div>
        <div className="work-selected row">
          <Title title="Selected Work" descript="作品選" />
          <div className="work-wrap">
            <WorkItem
              label="WEB DEV"
              title="美福大飯店"
              descript="-HTML -CSS -JQuery -RWD"
              url={ImgMayfull}
              alt="美福大飯店"
              go="/works/mayfull"
            />
            <WorkItem
              label="WEB DEV"
              title="富邦產險 保全聯盟鏈"
              descript="-HTML -CSS -JQuery -RWD"
              url={ImgFb}
              alt="富邦產險 保全聯盟鏈"
              go="/works/fb"
            />
            <WorkItem
            label="APP"
            title="丰盈錢包"
            descript="-UI/UX -iOS -Android"
            url={ImgWallet}
            alt="丰盈錢包"
            go="/works/wallet"
            />
            <WorkItem
              label="APP"
              title="全聯 “圈團”團購服務"
              descript="-UI/UX -iOS -Android -WebDev"
              url={ImgPxgroup}
              alt="全聯 “圈團”團購服務"
              go="/works/pxgroup"
            />
            <WorkItem
              label="Graphic"
              title="全聯線上購 隔日達"
              descript="-Event pages -Banner -Ads"
              url={ImgPxec}
              alt="全聯線上購 隔日達"
              go="/works/pxec"
            />
            {/* <WorkItem
              label="APP"
              title="iPet “愛寵GPS寵物追蹤器”"
              descript="-UI/UX -iOS -Android"
              url={ImgIpet}
              alt="iPet “愛寵GPS寵物追蹤器”"
              go="/works/ipet"
            /> */}
            {/* <WorkItem
              label="CIS"
              title="聚天股份有限公司"
              descript="-Branding "
              url={ImgGteng}
              alt="聚天股份有限公司"
              go="/works/gteng"
            /> */}
          </div>
          <Button text="MORE" go="/works" />
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
