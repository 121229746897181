import React from 'react';
import Button from '../../components/Button';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgWallet01 from '../../assets/images/detail/wallet01.png';
import ImgWallet02 from '../../assets/images/detail/wallet02.png';
import ImgWallet03 from '../../assets/images/detail/wallet03.png';
import ImgWallet04 from '../../assets/images/detail/wallet04.png';
import ImgWallet05 from '../../assets/images/detail/wallet05.png';
import ImgWallet06 from '../../assets/images/detail/wallet06.png';
import ImgWallet07 from '../../assets/images/detail/wallet07.png';
import ImgWallet08 from '../../assets/images/detail/wallet08.png';

const WalletScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='丰盈錢包' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>UI/UX</li>
            <li>iOS</li>
            <li>Android</li>
          </ul>
          <p>
          這款虛擬貨幣錢包應用程式擁有直觀的UI設計，可以輕鬆進行即時線上交易。<br/>
          介面簡潔明瞭，透過功能佈局引導，確保快速、安全地完成每一次交易，
          無論是購買、轉帳或查詢餘額，都能流暢實現。
          </p>
          <img src={ImgWallet01} alt='thumbnail01' />

          <Title title='Logo Design' descript='設計選稿' />
          <img src={ImgWallet02} alt='thumbnail02' />

          <Title title='onBoarding' descript='行動呼籲引導' />
          <img src={ImgWallet03} alt='thumbnail03' />

          <Title title='Components' descript='界面元素組件' />
          <p>構建應用程序和網站的基礎組件，提供一致的視覺風格和功能設計的界面元素，<br/>
          確保整個應用或網站擁有一致的外觀和行為，並提高開發和維護的效率。</p>
          <img src={ImgWallet04} alt='thumbnail04' />

          <Title title='App Design' descript='設計畫面說明' />
          <img src={ImgWallet05} alt='thumbnail05' />
          <img src={ImgWallet06} alt='thumbnail06' />
          <img src={ImgWallet07} alt='thumbnail07' />

          <Title title='Showcase' descript='頁面欣賞' />
          <img src={ImgWallet08} alt='thumbnail08' />

        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default WalletScreen;
