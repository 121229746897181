import React from 'react';

const SubTitle = props => {
  return (
    <div className='subtitle'>
      <h4>{props.subtitle}</h4>
    </div>
  );
};

export default SubTitle;
